<template>
	<v-row no-gutters>
		<v-col cols="5">
			<v-sheet class="py-3">
				<div class="d-flex align-center">
					<v-icon icon="mdi-account-multiple-outline" size="large"></v-icon>
					<p class="mx-3">Tím</p>
				</div>
			</v-sheet>
		</v-col>

		<v-col cols="7">
			<v-sheet class="py-3 d-flex flex-wrap flex-column">
				<template v-if="team">
					<p class="task-badge px-4 py-2 rounded-pill flex-shrink-0">{{ team.name }}</p>
					<div class="d-flex align-center flex-shrink-0 mt-2 task-badge px-4 py-2 rounded-pill" v-for="employee in enrichedEmployeesInTeam" :key="employee.id">
						<p class="employee-selected-avatar d-flex justify-center align-center">
							{{ employee._initials }}
						</p>
						<p class="ml-2">{{ employee._fullName }}</p>
					</div>
					<div class="mb-4"></div>
				</template>

				<p class="task-badge px-4 py-2 rounded-pill flex-shrink-0">Nepriradený</p>
				<div class="d-flex align-center flex-shrink-0 mt-2 task-badge px-4 py-2 rounded-pill" v-for="employee in enrichedEmployeesOutsideTeam" :key="employee.id">
					<p class="employee-selected-avatar d-flex justify-center align-center">
						{{ employee._initials }}
					</p>
					<p class="ml-2">{{ employee._fullName }}</p>
				</div>
			</v-sheet>
		</v-col>
	</v-row>
</template>


<script>
import tmUtils from '@/plugins/tm@_utils/tm.utils'

export default {
	props: {
		team: {
			type: Object,
			required: false
		},
		
		employees: {
			type: Array,
			required: true
		}
	},

	computed: {
		enrichedEmployeesInTeam() {
			if (!this.team?.employees)
				return []

			return tmUtils.enrichEmployees(this.team.employees)	
		},

		enrichedEmployeesOutsideTeam() {
			if (!this.team)
				return tmUtils.enrichEmployees(this.employees)

			const employeesOutsideOfTeam = this.employees.filter(employee => !this.team.employees.find(em => em.id == employee.id))
			return tmUtils.enrichEmployees(employeesOutsideOfTeam)
		}
	}
}
</script>