<template>
	<div ref="loadingContainer">
		<div v-if="!content"></div>
		<div v-else-if="content.error">{{ content.error }}</div>
		<div v-else-if="(typeof content == 'array' && !array.length) || (typeof content == 'object' && !Object.values(content).length)">{{ emptyMessage }}</div>
		<slot v-else></slot>
	</div>
</template>

<script>
export default {
	props: {
		content: {
			type: [Object, Array, Boolean],
			default: () => null,
		},

		emptyMessage: {
			type: String,
			default: 'No items'
		}
	},
	data() {
		return {
			loader: null
		}
	},

	async mounted() {
		if (this.content) return
		await this._startLoading()
	},

	methods: {
		async _startLoading() {
			// this.loader = await this.$loading.show()
		},

		_stopLoading() {
			// this.loader?.hide()
		}
	},

	watch: {
		// content(value) {
		// 	if (value) {
		// 		this._stopLoading()
		// 	}
		// 	else
		// 		this._startLoading()
		// }
	}
}
</script>

<style lang="sass" scoped>
// div
// 	height: calc( 100vh - 329px )
</style>