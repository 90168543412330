<template>
	<v-row no-gutters class="align-center">
		<v-col cols="5">
			<v-sheet class="py-3">
				<div class="d-flex align-center">
					<v-icon icon="mdi-drag-vertical" size="large"></v-icon>
					<p class="mx-3">Status</p>
				</div>
			</v-sheet>
		</v-col>
		<v-col cols="7">
			<v-sheet class="py-3">
				<p v-if="isFinished" class="status-badge px-4 py-2 rounded-pill">Ukončený</p>
				<p v-else class="status-badge px-4 py-2 rounded-pill">Neukončený</p>
			</v-sheet>
		</v-col>
	</v-row>
</template>

<script>
export default {
    props: {
        isFinished: {
            type: Number,
            default: () => 0
        }
    }
}
</script>
