<template>
	<v-row no-gutters class="align-center border-bottom">
		<v-col cols="5">
			<v-sheet class="py-3">
				<div class="d-flex align-center">
					<v-icon icon="mdi-clock-time-three-outline" size="large"></v-icon>
					<p class="mx-3">Zostávajúci čas</p>
				</div>
			</v-sheet>
		</v-col>

		<v-col cols="7">
			<v-sheet class="py-3">
				<p>{{ (hours).toFixed(2) }} h</p>
			</v-sheet>
		</v-col>
	</v-row>
</template>

<script>
export default {
	props: {
		hours: {
			type: Number,
			required: true
		}
	}
}
</script>
