export default {
	computed: {
		employeesOptions() {
			const selectedEmployees = [...this.mastertask.employees].map(employee => employee.id)

			return this.enrichedEmployees.filter(
				(employee) => !selectedEmployees.includes(employee.id)
			) || []
		},

		projectsOptions() {
			// Added to "paginate" projects, because v-autocomplete was unable to handle so many projects at once

			const pattern = /[^a-zA-Z0-9 ]/g

			const normalizedInput =  this.projectCodeSearch
				? this.projectCodeSearch.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(pattern, '')
				: ''

			const projects = this.projects.filter(project => {
				const normalizedProjectCode = project.code.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(pattern, '')
				return normalizedProjectCode.toLowerCase().includes(normalizedInput.toLowerCase())
			})

			let filteredProjects 	= projects?.length ? projects.slice(0, 10) : this.projects.slice(0, 10)

			if (!this.mastertask?.project_id)
				return filteredProjects

			const selectedProject 	= this.projects.find(project => project.id == this.mastertask?.project_id)
			filteredProjects 		= filteredProjects.filter(project => project.id != selectedProject.id)

			return [...filteredProjects, selectedProject]
		}
	},

	methods: {
		// onProjectSelected(id) {
		// 	const project = this.projects.find(project => project.id == id)
		// 	this.mastertask.project_code = project.code
		// 	this.mastertask.project_id 	 = project.id
		// },

		onEmployeeSelected(id) {
			const employee = this.enrichedEmployees.find(employee => employee.id == id)
			this.mastertask.employees.push(employee)
			this.showAddEmployeeInput = false
		},

		onEmployeeDurationChanged() {
			let totalDuration = 0

			this.mastertask.employees = this.mastertask.employees.map(employee => {
				if (typeof employee.duration == 'string') {
					totalDuration += parseFloat(employee.duration)
					employee.duration = parseFloat(employee.duration)
					employee.invalid = false
				}
				else if (typeof employee.duration == 'number') {
					totalDuration += employee.duration
					employee.invalid = false
				}

				return employee
			})

			this.mastertask.duration = totalDuration
		}
	},

	watch: {
		'mastertask.team_id'(value) {
			if (!value) return
			
			const team = this.teams.find(team => team.id == value)
			if (!team) return

			this.mastertask.team 		= JSON.parse(JSON.stringify(team))
			this.mastertask.employees   = [...this.mastertask.team.employees]
		},
	}
}