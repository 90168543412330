export default {
	// Mixin to fix autofocus on v-input in modals where date selects are used
	data() {
		return {
			preventInputFocus: false
		}
	},

	methods: {
		setFocusEvent() {
			document.querySelectorAll('.v-autocomplete input').forEach(input => {
				input.addEventListener('focus', this.onInputFocus)
			})
		},

		onDateFocus($event) {
			if ($event == undefined) {
				this.preventInputFocus = true
			}
		},

		onInputFocus($event) {
			if (this.preventInputFocus) {
				$event.target.blur()
				this.preventInputFocus = false
			}
		}
	}
}