<template>
	<v-app>
		<v-main>
			<router-view />
		</v-main>
	</v-app>

	<Z-team-modal 				ref="z-team-modal"  				@update="onTeamUpdate" />
	<Z-delete-team-modal 		ref="z-delete-team-modal" 			@update="onTeamUpdate" />

	<Z-task-modal 				ref="z-task-modal" 					@on-subtask-edited="onTaskEdited" />
	<Z-delete-task-modal 		ref="z-delete-task-modal" 			@on-subtask-deleted="onTaskDeleted" />
	<Z-delete-timeBlock-modal   ref="z-delete-timeblock-modal"      @on-subtask-edited="onTaskEdited" />
	<Z-delete-blocker-modal 	ref="z-delete-blocker-modal" 		@on-blocker-deleted="onBlockerDeleted" />
	<Z-delete-master-task-modal ref="z-delete-master-task-modal" 	@on-mastertask-deleted="onMasterTaskDeleted" />
	<Z-complete-task-modal 		ref="z-complete-task-modal" 		@on-subtask-edited="onTaskEdited" />
	<Z-complete-master-task-modal ref="z-complete-master-task-modal" @master-task-edited="onMasterTaskEdited" />
	<Z-task-add-time-modal 		ref="z-task-add-time-modal" />
	<Z-blocker-modal 			ref="z-blocker-modal" />

	<Z-master-task-modal 		ref="z-master-task-modal" 			@master-task-edited="onMasterTaskEdited" />
	<Z-delete-team-member-modal ref="z-delete-team-member-modal" />
	<Z-show-menu-modal 			ref="z-show-menu-modal" />
</template>

<script>
import ZDeleteTeamModal 		from '@/plugins/tm@modal/team/z-delete-team-modal.vue'
import ZTeamModal				from '@/plugins/tm@modal/team/z-team-modal.vue'
import ZDeleteTaskModal 		from '@/plugins/tm@modal/task/z-delete-task-modal.vue'
import ZDeleteTimeBlockModal	from '@/plugins/tm@modal/task/z-delete-timeblock-modal.vue'
import ZTaskModal 				from '@/plugins/tm@modal/task/z-task-modal.vue'
import ZDeleteBlockerModal		from '@/plugins/tm@modal/blocker/z-delete-blocker-modal.vue'
import ZCompleteTaskModal 		from '@/plugins/tm@modal/task/z-complete-task-modal.vue'
import ZCompleteMasterTaskModal from '@/plugins/tm@modal/master-task/z-complete-master-task-modal.vue'
import ZTaskAddTimeModal 		from '@/plugins/tm@modal/task/z-task-add-time-modal.vue'
import ZBlockerModal 			from '@/plugins/tm@modal/blocker/z-blocker-modal.vue'
import ZDeleteMasterTaskModal 	from '@/plugins/tm@modal/master-task/z-delete-master-task-modal.vue'
import ZMasterTaskModal 		from '@/plugins/tm@modal/master-task/z-master-task-modal.vue'
import ZDeleteTeamMemberModal	from '@/plugins/tm@modal/master-task/z-delete-team-member-modal.vue'
import ZShowMenuModal 			from '@/plugins/tm@modal/task-cell/z-show-menu-modal.vue'

export default {
	components: {
		ZDeleteTeamModal,
		ZTeamModal,
		ZDeleteTaskModal,
		ZDeleteBlockerModal,
		ZTaskModal,
		ZMasterTaskModal,
		ZCompleteTaskModal,
		ZTaskAddTimeModal,
		ZBlockerModal,
		ZDeleteMasterTaskModal,
		ZDeleteTeamMemberModal,
		ZShowMenuModal,
		ZCompleteMasterTaskModal,
		ZDeleteTimeBlockModal
	},

	mounted() {
		this.$events.on('show-menu-modal', (data) => {
			this.$refs['z-show-menu-modal'].showModal(data)
		})

		this.$events.on('show-delete-team-modal', ({team}) => {
			this.$refs['z-delete-team-modal'].showModal({ team })
		})

		this.$events.on('show-team-modal', ({ team }) => {
			this.$refs['z-team-modal'].showModal({ team })
		})

		this.$events.on('show-delete-task-modal', ({subtask}) => {
			this.$refs['z-delete-task-modal'].showModal({subtask})
		})

		this.$events.on('show-delete-timeblock-modal', ({subtask}) => {
			this.$refs['z-delete-timeblock-modal'].showModal({subtask})
		})

		this.$events.on('show-delete-blocker-modal', ({blocker}) => {
			this.$refs['z-delete-blocker-modal'].showModal({blocker})
		})

		this.$events.on('show-delete-master-task-modal', ({mastertask}) => {
			this.$refs['z-delete-master-task-modal'].showModal({mastertask})
		})

		this.$events.on('show-delete-team-member-modal', ({member, team}) => {
			this.$refs['z-delete-team-member-modal'].showModal({member, team})
		})

		this.$events.on('show-subtask-modal', ({subtask, editMode, mastertask}) => {
			if(subtask && subtask.id) {
				const id = subtask.id
				this.$router.push({ query: { 'subtask': id }})
			}
			this.$refs['z-task-modal'].showModal({subtask, editMode, mastertask})
		})

		this.$events.on('show-mastertask-modal', ({ mastertask, editMode }) => {
			if (mastertask && mastertask.id) {
				const id = mastertask.id
				this.$router.push({ query: { 'mastertask': id } })
			}
			this.$refs['z-master-task-modal'].showModal({mastertask, editMode})
		})

		this.$events.on('show-complete-task-modal', ({ subtask }) => {
			this.$refs['z-complete-task-modal'].showModal({ subtask })
		})

		this.$events.on('show-complete-master-task-modal', ({ mastertask }) => {
			this.$refs['z-complete-master-task-modal'].showModal({ mastertask })
		})

		this.$events.on('show-blocker-modal', ({blocker = {}}) => {
			this.$refs['z-blocker-modal'].showModal({blocker})
		})

		this.$events.on('show-task-add-time-modal', ({subtask}) => {
			this.$refs['z-task-add-time-modal'].showModal({ subtask })
		})

		this.$events.on('on-subtask-edited', () => {
			this.$store.dispatch('tm/reloadSubtasks')
		})

		this.$events.on('on-mastertask-edited', () => {
			this.$store.dispatch('tm/reloadMastertasks')
		})

		this.$events.on('on-mastertask-deleted', () => {
			this.$store.dispatch('tm/reloadMastertasks')
		})

		this._showModalsAfterInicialization()
	},

	methods: {
		onTeamUpdate(team) {
			this.$events.emit('on-team-update', team)
		},

		onTaskDeleted(task) {
			this.$events.emit('on-subtask-deleted', task)
		},

		onBlockerDeleted(task) {
			this.$events.emit('on-blocker-deleted', task)
		},

		onTaskEdited(task) {
			this.$events.emit('on-subtask-edited', task)
		},

		onMasterTaskDeleted(mastertask){
			this.$events.emit('on-mastertask-deleted', mastertask)
		},

		onMasterTaskEdited(mastertask){
			this.$events.emit('on-mastertask-edited', mastertask)
		},

		_showModalsAfterInicialization(repeat = 0) {
			setTimeout(() => {
				if (repeat > 50)
					return this.$router.push({name: this.$route.name})
				else if (!this.$store.getters['tm/isInitialized'])
					return this._showModalsAfterInicialization(repeat + 1)
				else if (this.$route.query['mastertask'])
					return this.$events.emit('show-mastertask-modal', { mastertask: { id: this.$route.query['mastertask'] }, editMode: false })
				else if (this.$route.query['subtask'])
					return this.$events.emit('show-subtask-modal', { subtask: { id: this.$route.query['subtask'] }, editMode: false })
			}, 300)
		}
	}
}
</script>