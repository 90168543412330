<template>
	<v-dialog max-width="750" v-model="visible" persistent>
		<v-card>
			<div class="d-flex justify-space-between align-center">
				<h1>Pridať odpracovaný čas</h1>

				<v-icon @click.stop="closeModal()" icon="mdi-close" size="large"></v-icon>
			</div>
			<v-card-actions class="mt-10 d-flex justify-start align-center">
				<v-text-field v-model="form.hours" variant="solo" bg-color="white" type="number" :hint="v$.form.hours.$errors ? errorUtils.parseErrors(v$.form.hours.$errors) : null" :class="{ error: v$.form.hours.$errors.length }" class="mb-2" label="Odpracovaný čas" />
			</v-card-actions>
			<v-btn @click="submit()" class="button primary mt-5">Potvrdiť</v-btn>
		</v-card>
	</v-dialog>
</template>

<script>
import 			errorUtils 				from '@/plugins/lib@auth/_utils/error.utils'
import 			useVuelidate 			from '@vuelidate/core'
import { required, numeric, minValue } 	from '@vuelidate/validators'
import 			modalMixin 				from '@/plugins/tm@modal/_mixins/modal.mixin'
import 			tmUtils 				from '@/plugins/tm@_utils/tm.utils'

export default {
	mixins: [ modalMixin ],
	emits: ['close', 'on-subtask-edited'],

	data() {
		return {
			v$: useVuelidate(),
			errorUtils,
			visible: false,
			subtask: null,
			form: {
				hours: null
			}
		}
	},

	validations() {
		return {
			form: {
				hours: {required, numeric, minValue: minValue(0.1)}
			}
		}
	},

	methods: {
		async showModal({ subtask }) {
			this.subtask = subtask
			this.visible = true
			this.form.hours = null
			this.$nextTick(() => this.v$.$reset())
		},

		async submit() {
			if (!await this.v$.$validate())
				return

			if (this.subtask.deadline) this.subtask.deadline = tmUtils.formatDateTimeForBackend(this.subtask.deadline)

			this.subtask.timesheets.push({
				id: null,
				employee_id: this.subtask.employee_id,
				time: typeof this.form.hours == 'string' ? parseFloat(this.form.hours) : this.form.hours
			})

			const payload = {
				...this.subtask,
				timesheets: this.subtask.timesheets.map((timesheet) => {
					delete timesheet.employee_name
					return timesheet
				})
			}

			try {
				const { subtask } = await this.$axios.put_auth(`v1/subtasks/${this.subtask.id}`, payload)

				this.closeModal()
				this.$events.emit('on-subtask-edited', subtask)
			} catch (error) {
				this.$toast.error(error.message || error)
			}
		},

		closeModal() {
			this.visible = false
			this.v$.$reset()
		}
	}
}
</script>