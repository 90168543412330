<template>
	<v-row no-gutters class="align-center">
		<v-col cols="5">
			<v-sheet class="py-3">
				<div class="d-flex align-center">
					<v-icon icon="mdi-calendar-week" size="large"></v-icon>
					<p class="mx-3">Deadline</p>
				</div>
			</v-sheet>
		</v-col>
		<v-col cols="7">
			<v-sheet class="py-3">
				<p :class="`${tmUtils.isAfter(deadline) ? 'text-black' : 'text-red-darken-4'}`">{{ tmUtils.formatDateTime(deadline) }}</p>
			</v-sheet>
		</v-col>
	</v-row>
</template>

<script>
import tmUtils from '@/plugins/tm@_utils/tm.utils'

export default {
	props: {
		deadline: {
			type: String,
			required: true
		}
	},

	data() {
		return {
			tmUtils
		}
	}
}
</script>