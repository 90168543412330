import moment from 'moment'

export default {
	enrichEmployees(employees) {
		return employees.map(employee => ({
			...employee, 
			_fullName: `${ employee.name } ${ employee.surname }`,
			_initials: employee.name.charAt(0) + employee.surname.charAt(0)
		}))
	},

	formatDateTime(date) {
		moment(date)
		return moment(date).format('DD.MM.yyyy, HH:mm')
	},

	formatDateTimeForBackend(date) {
		return moment(date).format('YYYY-MM-DD HH:mm:ss')
	},

	formatDate(date) {
		return moment(date).format('DD.MM.yyyy')
	},

	isBefore(date, compareTo = null) {
		return moment(date).isBefore(moment(compareTo))
	},

	isAfter(date, compareTo = moment()) {
		return moment(date).isAfter(moment(compareTo))
	}
}