import { Quill } from '@vueup/vue-quill'

export function registerQuillUrlSanitizer() {
    const Link = Quill.import('formats/link')

    // Extend the protocol whitelist
    Link.PROTOCOL_WHITELIST.push('smb', 'fmp', 'radar', 'rdar', 'sms', '\\')

    class CustomLinkSanitizer extends Link {
        static sanitize(url) {
            const sanitizedUrl = super.sanitize(url)

            if (!sanitizedUrl || sanitizedUrl === 'about:blank') return sanitizedUrl

            const protocolWhitelist = Link.PROTOCOL_WHITELIST
            const hasWhitelistedProtocol = protocolWhitelist.some(protocol => sanitizedUrl.startsWith(protocol))

            if (hasWhitelistedProtocol) return sanitizedUrl

            return `https://${sanitizedUrl}`
        }       
    }

    Quill.register('formats/link', CustomLinkSanitizer, true)
}