import { useToast } from 'vue-toast-notification';
const toast = useToast();

declare global {
  interface Window {
	toast: typeof toast;
  }
}

window.toast = toast;

export function detectOS() {
	const platform = window.navigator.platform
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']

	if (macosPlatforms.indexOf(platform) !== -1) {
		return 'mac'
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		return 'win'
	}
	return 'win'
}

export function prepareHTMLDescription(description) {
	if (!description) return description

	const os = detectOS()

	if (os === 'mac') {
		description = description.replace(/\\\\/g, 'smb://')

		// find all smb links and replace them with clickable links, separated by ' ', '<', '>', '"', and '(', ')' characters
		description = description = description.replace(/(smb:\/\/[^\s<>"()]+)/g, function(match) {
			return '<a href="' + match.replace(/\\/g, '/') + '">' + match.replace(/\\/g, '/') + '</a>'
		})
	} else {
		description = description.replace(/smb:\/\//g, '\\\\')

		
		// find all windows links and replace them with clickable links, separated by ' ', '<', '>', '"', and '(', ')' characters
		description = description.replace(/(\\\\[^\s<>"()]+)/g, function(match) {
			match = match.replaceAll('/', '\\')
			return '<span style="cursor: pointer; text-decoration: underline" onclick="' + getCopyFunction(match) + '">' + match + '</span>';
		});
	}

	return description;
}

function getCopyFunction(text) {
	return `
		if (navigator.clipboard && navigator.clipboard.writeText) {
			navigator.clipboard.writeText('${text.replace(/\\/g, '\\\\')}').then(() => {
				window.toast.success('Link copied to clipboard!')
			}).catch(err => {
				window.toast.error('Failed to copy link to clipboard!')
			});
		} else {
			const input = document.createElement('input');
			input.style.position = 'fixed';
			input.style.opacity = 0;
			input.style.pointerEvents = 'none';
			input.value = '${text.replace(/\\/g, '\\\\')}';
			document.body.appendChild(input);
			input.select();
			input.focus();
			document.execCommand('copy');
			document.body.removeChild(input);
			window.toast.success('Link copied to clipboard!')
		}
	`
}