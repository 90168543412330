export default {
	mounted() {
		window.addEventListener('keydown', this.handleKeyDown)
	},

	methods: {
		handleKeyDown(event) {
			// if (event.keyCode === 27) this.closeModal()
			if (event.keyCode === 27) this.visible = false
		}
	},
}