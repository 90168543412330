<template>
	<v-row no-gutters>
		<v-col cols="5">
			<v-sheet class="py-3">
				<div class="d-flex align-center">
					<v-icon icon="mdi-calendar-week" size="large"></v-icon>
					<p class="mx-3">Trvanie</p>
				</div>
			</v-sheet>
		</v-col>

		<v-col cols="7">
			<v-sheet class="py-3">
				<div class="d-flex align-center mb-3">
					<v-icon @click="showModal = true" class="add-date cursor-pointer mr-2" icon="mdi-plus" size="medium" />
					<p class="task-badge rounded-pill">Pridať záznam</p>
				</div>

				<div v-for="(timeblock, index) in timeblocks" :key="timeblock.id" class="d-flex align-center mb-3">
					<v-icon @click.stop="subtask.id ? deleteTimeblock(timeblock) : deleteTimeblockWithoutId(index)" class="circle-border cursor-pointer mr-2" icon="mdi-minus" size="medium" />
					<p class="task-badge rounded-pill" :class="{'strike-through': timeblock.is_finished}">{{ tmUtils.formatDateTime(timeblock.start) }} - {{ tmUtils.formatDateTime(timeblock.end) }}</p>
					<v-icon v-show="subtask.id" @click.stop="toggleTimeblockIsFinished(timeblock)" class="circle-border cursor-pointer ml-2" :class="{'completed': timeblock.is_finished}" icon="mdi-check" size="medium" />
				</div>
			</v-sheet>
		</v-col>
	</v-row>

	<v-dialog max-width="750" v-model="showModal" persistent>
		<v-card>
			<div class="d-flex justify-space-between align-center">
				<h1>Pridať dátum</h1>
				<v-icon @click.stop="closeModal()" icon="mdi-close" size="large"></v-icon>
			</div>

			<v-card-actions class="mt-10 d-flex flex-column modal-content">
				<Datepicker v-model="form.date" format="dd.MM.yyyy" locale="sk" select-text="Vybrať" cancel-text="Zrušiť" placeholder="Dátum" :auto-apply="true" :enable-time-picker="false" class="mb-5 modal-input" :class="{error: v$.form.date.$errors.length}" />
				<v-text-field v-model="form.starts_at_time" type="time" variant="solo" bg-color="white" :hint="v$.form.starts_at_time.$errors ? errorUtils.parseErrors(v$.form.starts_at_time.$errors) : null" :class="{ error: v$.form.starts_at_time.$errors.length }" class="mb-1 modal-input" label="Začiatok" />
				<v-text-field v-model="form.ends_at_time" 	type="time" variant="solo" bg-color="white" :hint="v$.form.ends_at_time.$errors ? errorUtils.parseErrors(v$.form.ends_at_time.$errors) : null" :class="{ error: v$.form.ends_at_time.$errors.length }" class="modal-input" label="Koniec" />
			</v-card-actions>

			<v-btn @click="submit()" class="button primary mt-5">Potvrdiť</v-btn>
		</v-card>
	</v-dialog>
	<v-dialog v-model="visible" max-width="750" persistent>
		<v-card>
			<div class="d-flex justify-space-between align-center">
				<h1 class="text-red">Ste si istý, že chcete pridať timeblock po deadline <br> <b class="text-black">z mastertasku {{ subtask?.project_code }} ?</b></h1>

				<v-icon
					@click.stop="visible = false"
					icon="mdi-close"
					size="large"
					class="mb-15"
				></v-icon>
			</div>
			<v-card-actions class="mt-10 d-flex justify-start align-center mobile-view">
				<v-btn class="button primary half" variant="flat" @click.stop="confirmSubmit()">ÁNO</v-btn>
				<v-btn class="button primary half outline" @click.stop="visible = false">ZRUŠIŤ</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>


<script>
import tmUtils 		from '@/plugins/tm@_utils/tm.utils'
import errorUtils 	from '@/plugins/lib@auth/_utils/error.utils'
import useVuelidate from '@vuelidate/core'
import { required }	from '@vuelidate/validators'
import Datepicker 	from '@vuepic/vue-datepicker'
import moment 		from 'moment'
import modalMixin 	from '@/plugins/tm@modal/_mixins/modal.mixin'
import { mapGetters } from 'vuex'


export default {
	mixins: [ modalMixin ],
	emits: ['update-subtask'],

	components: {
		Datepicker
	},

	props: {
		timeblocks: {
			type: Array,
			required: true
		},

		subtask: {
			type: Object,
			required: true
		}
	},

	data() {
		return {
			v$: useVuelidate(),
			errorUtils,
			tmUtils,
			showModal: false,
			visible: false,
			form: {
				date: null,
				starts_at_time: '09:00',
				ends_at_time: '16:00'
			}
		}
	},

	validations() {
		return {
			form: {
				date: { required },
				starts_at_time: { required },
				ends_at_time: { required }
			}
		}
	},

	computed: {
		...mapGetters('tm', ['mastertasks'])
	},

	methods: {
		async submit() {
			if (!await this.v$.$validate())
				return

			const subtask = JSON.parse(JSON.stringify(this.subtask))
			const splittedStartsAtTime 	= this.form.starts_at_time.split(':')
			const splittedEndsAtTime 	= this.form.ends_at_time.split(':')
			const start = moment(this.form.date).hours(parseInt(splittedStartsAtTime[0])).minutes(parseInt(splittedStartsAtTime[1])).toISOString()
			const end   = moment(this.form.date).hours(parseInt(splittedEndsAtTime[0])).minutes(parseInt(splittedEndsAtTime[1])).toISOString()

			if (!subtask?.timeblocks) { subtask.timeblocks = [] }
			if (!subtask?.timesheets) { subtask.timesheets = [] }
			if (!subtask?.is_finished) { subtask.is_finished = 0 }

			subtask.timeblocks.push({
				end: tmUtils.formatDateTimeForBackend(end),
				id: null,
				is_finished: 0,
				start: tmUtils.formatDateTimeForBackend(start)
			})

			if(subtask.deadline) subtask.deadline = tmUtils.formatDateTimeForBackend(this._mergeDeadlineTimeDate())

			const isAfterDeadline = this.isAfterDeadline()

			if (isAfterDeadline) {
				this.visible = true
			} else {
				if (!subtask.id) {
					this.$emit('update-unfinished-subtask', subtask)
				} else {
					this._update(subtask)
				}
			}
		},

		async confirmSubmit() {
			const subtask = JSON.parse(JSON.stringify(this.subtask))
			const splittedStartsAtTime 	= this.form.starts_at_time.split(':')
			const splittedEndsAtTime 	= this.form.ends_at_time.split(':')
			const start = moment(this.form.date).hours(parseInt(splittedStartsAtTime[0])).minutes(parseInt(splittedStartsAtTime[1])).toISOString()
			const end   = moment(this.form.date).hours(parseInt(splittedEndsAtTime[0])).minutes(parseInt(splittedEndsAtTime[1])).toISOString()

			if (!subtask?.timeblocks) { subtask.timeblocks = [] }
			if (!subtask?.timesheets) { subtask.timesheets = [] }
			if (!subtask?.is_finished) { subtask.is_finished = 0 }

			subtask.timeblocks.push({
				end: tmUtils.formatDateTimeForBackend(end),
				id: null,
				is_finished: 0,
				start: tmUtils.formatDateTimeForBackend(start)
			})

			if(subtask.deadline) subtask.deadline = tmUtils.formatDateTimeForBackend(this._mergeDeadlineTimeDate())

			if (!subtask.id) {
				this.$emit('update-unfinished-subtask', subtask)
				this.closeModal()
				return
			}

			this.visible = false
			this._update(subtask)
		},

		async toggleTimeblockIsFinished(originalTimeblock) {
			// TODO: Pridat odpracovany cas modal?
			const subtask = JSON.parse(JSON.stringify(this.subtask))
			const timeblock = JSON.parse(JSON.stringify(originalTimeblock))

			timeblock.is_finished = timeblock.is_finished == 1 ? 0 : 1

			try {
				const {data: newTimeblock} = await this.$axios.put_auth(`v1/timeblocks/${timeblock.id}`, timeblock)

				subtask.timeblocks = subtask.timeblocks.map(originalTimeblock => {
					if (originalTimeblock.id == newTimeblock.id)
						return newTimeblock

					return originalTimeblock
				})

				this.$events.emit('on-subtask-edited', subtask)
				this.$emit('update-subtask', subtask)

				if (timeblock.is_finished)
					this.$events.emit('show-task-add-time-modal', { subtask })
			} catch (error) {
				this.$toast.error(error.message || error)
			}
		},

		async deleteTimeblock({ id }) {
			const subtask = JSON.parse(JSON.stringify(this.subtask))

			subtask.timeblocks = subtask.timeblocks.filter(timeblock => timeblock.id != id)
			if(subtask.deadline) subtask.deadline = tmUtils.formatDateTimeForBackend(this._mergeDeadlineTimeDate())

			this._update(subtask)
		},

		deleteTimeblockWithoutId(i) {
			this.$emit('delete-timeblock')
		},

		async _update(data) {
			try {
				const timesheets = data.timesheets.map((timesheet) => {
					delete timesheet.employee_name
					return timesheet
				})

				const { subtask } = await this.$axios.put_auth(`v1/subtasks/${data.id}`, {...data, timesheets})

				this.closeModal()
				this.$events.emit('on-subtask-edited', subtask)
			} catch (error) {
				this.$toast.error(error.message || error)
			}
		},

		isAfterDeadline() {
			const deadline = this.getDeadline(this.subtask)

			if (!deadline)
				return

			const mDate = moment(this.form.date)
			const mDeadline = moment(deadline)

			const [hours, minutes] = this.form.ends_at_time.split(':').map(Number)

			mDate.hours(hours)
			mDate.minutes(minutes)

			return mDate.isAfter(mDeadline)
		},

		getDeadline(subtask) {
			if (subtask?.deadline)
				return subtask.deadline

			return subtask.master_task_deadline
		},

		_mergeDeadlineTimeDate() {
			const date = new Date(this.subtask.deadline)
			const timeParts = this.subtask._deadline_time.split(':')

			date.setHours(parseInt(timeParts[0]))
			date.setMinutes(parseInt(timeParts[1]))

			return date
		},

		closeModal() {
			this.form = {
				date: null,
				starts_at_time: '09:00',
				ends_at_time: '16:00'
			}

			this.showModal = false
			this.$nextTick(() => this.v$.$reset())
		}
	}
}
</script>

<style lang="sass">
.completed
	background: #06dece
</style>