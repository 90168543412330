import { $t } from '@/plugins/app/_config/main'

export default {
	parseAndTranslate(data) {
		const errorsArray = this._parseToArrayOfStrings(data)
		return errorsArray.join(', ')
	},

	_parseToArrayOfStrings(data) {
		if (data?.data?.message) {
			return [$t(data.data.message)]
		}

		if (typeof data == 'string') {
			return [$t(data)]
		}

		if (data?.response?.data)
			return Object.values(data?.response?.data)

		if (data && data.error && typeof data.error == 'string') {
			return [$t(data.error)]
		}

		if (data && data.error && typeof data.error == 'object') {
			return Object.values($t(data.error))
		}

		if (data.data && data.data.error && typeof data.data.error == 'string') {
			return [$t(data.data.error)]
		}

		if (data && data.data && data.data.error && typeof data.data.error == 'object') {
			return Object.values(data.data.error).map(error => $t(error[0]) + ' ')
		}

		if (data.statusText) {
			return [$t(data.statusText)]
		}

		return [data]
	}
}