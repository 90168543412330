export default {
	methods: {
		customFilter(item, input) {
			// Replace accented characters with non-accented equivalents
			const normalizedItem = item.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
			const normalizedInput = input.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
			
			// Remove any character that is not a letter, number, or space from both the item and the input
			const pattern = /[^a-zA-Z0-9 ]/g;
			const cleanedItem = normalizedItem.replace(pattern, '');
			const cleanedInput = normalizedInput.replace(pattern, '');
			
			// Check if the cleaned item starts with the cleaned input and return empty value for dropdown if present
			return cleanedItem.toLowerCase().includes(cleanedInput.toLowerCase()) || item === '';
		}
	}
}