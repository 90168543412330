<template>
	<div class="px-5 py-5 border-bottom" v-if="description" >
		<p>Popis zadania</p>
		<section v-html="preparedDescription" class="description-detail px-2 py-2 w-100 rounded mt-2" />
	</div>
  
	<div class="px-5 py-5 border-bottom" v-if="inheritedDescription">
		<p>Popis mastertasku</p>
		<section v-html="preparedInheritedDescription" class="description-detail px-2 py-2 w-100 rounded mt-2" />
	</div>
</template>

<script>
import { prepareHTMLDescription } from '@/plugins/tm@_utils/server-links.utils'

export default {
	props: {
		description: {
			type: String || null,
			default: () => null
		},

		inheritedDescription: {
			type: String || null,
			default: () => null
		}
	},

	data() {
		return {
			copiedIndices: [],
			preparedDescription: '',
			preparedInheritedDescription: ''
		}
	},

	mounted() {
		this.preparedDescription = prepareHTMLDescription(this.description)
		this.preparedInheritedDescription = prepareHTMLDescription(this.inheritedDescription)
	}
}
</script>
