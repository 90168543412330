<template>
	<v-dialog v-model="visible" max-width="750" max-height="90%" height="700px" persistent>
		<W-loadable :content="team" class="h-100">
			<v-card height="100%" class="d-flex flex-column">
				<div class="d-flex justify-space-between align-center modal-header">
					<h1>{{ team.id ? 'Upraviť tím' : 'Vytvoriť tím' }}</h1>
					<v-icon @click.stop="closeModal()" icon="mdi-close" size="large"></v-icon>
				</div>

				<v-card-actions class="d-flex flex-column modal-content">
					<v-text-field class="modal-input" label="Názov tímu" variant="solo"
						:class="{ error: v$.team.name.$errors.length }" :hint="
							v$.team.name.$errors
								? errorUtils.parseErrors(v$.team.name.$errors)
								: null
						" v-model="team.name"></v-text-field>

					<div class="relative w-100" v-for="(employee, index) in team.employees" :key="employee.id">
						<v-text-field class="modal-input" disabled label="Zamestanec" variant="solo"
							v-model="team.employees[index]._fullName">
						</v-text-field>

						<v-icon class="remove-input" @click.stop="team.employees.splice(index, 1)" icon="mdi-close"
							size="small"></v-icon>
					</div>

					<v-autocomplete :custom-filter="customFilter" :match-any="true" v-model="employeeSelect" label="Priradený"
						:class="{ error: v$.team.employees.$errors.length }" :items="employeesOptions"
						item-title="_fullName" item-value="id" variant="solo" class="modal-input" clearable
						ref="autocomplete" />
				</v-card-actions>

				<v-btn class="button primary block" @click="save()">{{ team.id ? 'UPRAVIŤ' : 'VYTVORIŤ' }}</v-btn>
			</v-card>
		</W-loadable>
	</v-dialog>
</template>

<script>
import useVuelidate 		from '@vuelidate/core'
import { required } 		from '@vuelidate/validators'
import errorUtils			from '@/plugins/lib@auth/_utils/error.utils'
import tmUtils 				from '@/plugins/tm@_utils/tm.utils'
import { mapGetters } 		from 'vuex'
import AutocompleteMixin 	from '@/plugins/tm@_mixins/autocomplete.mixin'
import modalMixin 			from '@/plugins/tm@modal/_mixins/modal.mixin'

const atLeastOne = (value) => value.length

export default {
	mixins: [AutocompleteMixin, modalMixin],
	emits: ['close', 'update'],

	data() {
		return {
			visible: false,
			v$: useVuelidate(),
			errorUtils,
			employeeSelect: null,
			team: null
		}
	},

	computed: {
		...mapGetters('tm', [
			'enrichedEmployees'
		]),

		employeesOptions() {
			const selectedEmployeesIds = this.team.employees.map(
				(employee) => employee.id
			)

			return this.enrichedEmployees.filter(employee => !selectedEmployeesIds.includes(employee.id))
		}
	},

	validations() {
		return {
			team: {
				name: { required },
				employees: { atLeastOne }
			},
		}
	},

	methods: {
		async showModal({ team = null }) {
			this.visible = true
			this.team = null
			this.$nextTick(() => this.v$.$reset())

			try {
				if (team?.id) {
					const t = await this.$axios.get_auth_data(`v1/teams/${team.id}`)
					this.team = this._enrichTeam(t)
				} else {
					this.team = { employees: [] }
				}
			} catch (error) {
				this.$toast.error(this.$parseError(error))
			}
		},

		closeModal() {
			this.visible = false
			this.$emit('close', this.team)
		},

		async save() {
			if (!(await this.v$.$validate())) return

			try {
				if (this.team.id) {
					const { team } = await this.$axios.put_auth(`v1/teams/${this.team.id}`, this.team)
					
					const fullTeamResource = await this.$axios.get_auth_data(`v1/teams/${team.id}`)
					this.$store.commit('tm/update_team', fullTeamResource)
				} else {
					const { team } = await this.$axios.post_auth(`v1/teams`, this.team)

					const fullTeamResource = await this.$axios.get_auth_data(`v1/teams/${team.id}`)
					this.$store.commit('tm/save_team', fullTeamResource)
				}

				this.closeModal()
			} catch (error) {
				this.$toast.error(this.$parseError(error))
			}
		},

		_enrichTeam(team) {
			team.employees = tmUtils.enrichEmployees(team.employees)
			return team
		}
	},

	watch: {
		employeeSelect(employeeId) {
			if (!employeeId)
				return

			const employee = this.enrichedEmployees.find(employee => employee.id == employeeId)
			this.team.employees.push(employee)
			this.employeeSelect = null
			this.$refs.autocomplete.search = ''
		}
	}
}
</script>