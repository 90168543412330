<template>
	<v-row no-gutters class="align-center">
		<v-col cols="5">
			<v-sheet class="py-3">
				<div class="d-flex align-center">
					<v-icon icon="mdi-link" size="large"></v-icon>
					<p class="mx-3">Mastertask</p>
				</div>
			</v-sheet>
		</v-col>
		<v-col cols="7">
			<v-sheet class="py-3">
				<p class="task-badge px-4 py-2 rounded-pill hover" @click="openMastertask()" :style="{backgroundColor: color}">
					{{ name }}
				</p>
			</v-sheet>
		</v-col>
	</v-row>
</template>

<script>
export default {
	emits: ['close-modal'],

	props: {
		name: {
			type: String,
			required: true
		},

		id: {
			type: Number,
			required: true
		},

		color: {
			type: String,
			required: true
		}
	},

	methods: {
		openMastertask() {
			this.$emit('close-modal')
			this.$events.emit('show-mastertask-modal', {mastertask: {id: this.id}, editMode: false})
		}
	}
}
</script>
