import { createApp } 	from 'vue'
import { IonicVue } 	from '@ionic/vue'
import { loadFonts } 	from './webfontloader'
import vuetify 			from './vuetify'
import { registerQuillUrlSanitizer } from './quill.util'
import loader 			from 'vue-loading-overlay'
import App 				from './App.vue'

import router 			from './router'
import store 			from '@/store'
import w 				from '@/plugins/w/w'
import axiosCustom      from '@/plugins/w@custom/axios'
import tmStore 			from '@/plugins/tm@_store'
import vueCal 			from '@/plugins/app@vendor/vue-cal'
import { Options } 		from '@/plugins/w/w/types'

import '@ionic/vue/css/core.css'
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

import '@/plugins/app/_theme/index.sass'
import 'vue-loading-overlay/dist/vue-loading.css'
import '@vuepic/vue-datepicker/dist/main.css'

loadFonts()
registerQuillUrlSanitizer()

const options: Options = {
	axios: {
		disabled: true
	},
	i18n: {
		languages: [
			{
				title: "Slovenský", flag: "sk", value: "sk"
			}
		],
		defaultLanguage: 'sk'
	},
	moxios: {
		routes: {
			// 'GET v1/auth/info': true,
			// 'POST v1/auth/forgot': true,
			// 'POST v1/auth/reset': true,
			// 'POST v1/auth/info': true,
			// 'POST v1/auth/invalidate': true,
			// 'POST v1/auth/login': true,
			// 'POST v1/auth/post': true,

			'GET v1/overview/teams': true,
			'GET v1/overview/tasks': true,
			// 'GET v1/search': true,
		}
	}
}

const app = createApp(App)
	.use(router)
	.use(store)
	.use(axiosCustom)
	.use(w, options)
	.use(IonicVue)
	.use(vuetify)
	.use(vueCal)
	.use(tmStore)
	.use(loader, {
		color: '#6200EE'
	})
	.mount('#app')

// @ts-expect-error
export const $t = app.$i18n.t
