import { Options } 	from './types'
// import { Toast } from './models/Toast'
import 'vue-toast-notification/dist/theme-sugar.css'
import VueToast from 'vue-toast-notification'

export default {
	install: (app, options: Options) => {
		if (options && options.disabled === true) return

		app.use(VueToast)

		// app.config.globalProperties.$wToast = new Toast()
		app.config.globalProperties.$parseError = (error) => error?.data?.message || error?.response?.data?.message || error?.message || error
	}
}