<template>
	<v-dialog v-model="visible" max-width="750" persistent>
		<v-card>
			<div class="d-flex justify-space-between align-center">
				<h1>Vytvoriť</h1>
				<v-icon @click.stop="closeModal()" icon="mdi-close" size="large"></v-icon>
			</div>

			<v-card-actions class="mt-10 d-flex justify-start align-center mobile-view">
				<v-btn class="button primary half outline" @click.stop="createTask()"
					>SUB TASK</v-btn
				>
				<v-btn class="button primary half outline" @click.stop="createBlocker()"
					>BLOCKER</v-btn
				>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import modalMixin from '@/plugins/tm@modal/_mixins/modal.mixin'

export default {
	emits: ['update', 'data'],
	props: ['data'],
	mixins: [ modalMixin ],

	data() {
		return {
			visible: false,
			localData: null,
		}
	},

	mounted() {
		this.$events.on('blocker-created', () => {
			this.closeModal()
		})

		this.$events.on('task-created', () => {
			this.closeModal()
		})
	},

	methods: {
		async showModal(data) {
			this.localData = data
			this.visible = true
		},

		closeModal() {
			this.visible = false
		},

		createTask() {
			this.$events.emit('show-subtask-modal', {
				subtask: {
					deadline: this.localData.date,
					_deadline_time: this.localData.time,
					employee_id: this.localData.employee
				},
				onTaskCreated: () => {
					this.closeModal()
				}
			})
		},

		createBlocker() {
			this.$events.emit('show-blocker-modal', {
				blocker: {
					starts_at_time: this.localData.time,
					datepicker: this.localData.date,
					employee_id: this.localData.employee
				},
				onBlockerCreated: () => {
					this.closeModal()
				}
			})
		},
	},
}
</script>