<template>
	<v-row no-gutters class="align-center">
		<v-col cols="5">
			<v-sheet class="py-3">
				<div class="d-flex align-center">
					<v-icon icon="mdi-calendar-week" size="large"></v-icon>
					<p class="mx-3">Deadline</p>
				</div>
			</v-sheet>
		</v-col>
		<v-col cols="7">
			<v-sheet class="py-3">
				<p v-if="subtask.deadline" :class="`${tmUtils.isAfter(subtask.deadline) ? 'text-black' : 'text-red-darken-4'}`">{{ tmUtils.formatDate(subtask.deadline) }} {{ deadlineTime }}</p>
				<v-tooltip v-else text="Deadline z Mastertasku" location="end">
					<template v-slot:activator="{ props }">
						<span class="cursor-pointer" v-bind="props">{{ getMasterTaskDeadline(subtask) }}*</span>
					</template>
				</v-tooltip>
			</v-sheet>
		</v-col>
	</v-row>
</template>

<script>
import tmUtils 			from '@/plugins/tm@_utils/tm.utils'
import moment 			from 'moment'
import { mapGetters } 	from 'vuex'

export default {
	props: {
		subtask: {
			type: [String, Object, null],
			required: true
		}
	},

	computed: {
		...mapGetters('tm', ['mastertasks']),

		deadlineTime() {
			const time = this.subtask?._deadline_time
			if (time)
				return moment(time, 'HH:mm:ss').format('HH:mm')
			return ''
		}
	},

	data() {
		return {
			tmUtils
		}
	},

	methods: {
		getMasterTaskDeadline(subtask) {
			if (subtask?.deadline)
				return subtask.deadline

			return subtask.master_task_deadline
		}
	}
}
</script>