import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store'

const routes: Array<RouteRecordRaw> = [
	{
		path: '',
		redirect: '/auth'
	},
	{
		path: '/auth/',
		component: () => import('@/plugins/app/_layout/auth/auth.layout.vue'),
		children: [
			{
				path: '',
				redirect: '/auth/login'
			},
			{
				path: '/auth/login',
				name: 'Login',
				component: () => import('@/plugins/lib@auth/login/login.vue'),
				meta: {requiresAuth: false}
			},
			{
				path: '/auth/forgot-password',
				name: 'Forgot password',
				component: () => import('@/plugins/lib@auth/forgot-password/forgot-password.vue'),
				meta: {requiresAuth: false}
			},
			{
				path: '/auth/forgot-success',
				name: 'Forgot success',
				component: () => import('@/plugins/lib@auth/forgot-success/forgot-success.vue'),
				meta: {requiresAuth: false}
			},
			{
				path: '/auth/reset-password',
				name: 'Reset password',
				component: () => import('@/plugins/lib@auth/reset-password/reset-password.vue'),
				meta: {requiresAuth: null}
			}
		]
	},
	{
		path: '/app',
		component: () => import('@/plugins/app/_layout/app/app.layout.vue'),
		children: [
			{
				path: '',
				redirect: '/app/dashboard'
			},
			{
				path: '/app/dashboard',
				redirect: '/app/dashboard/tasks',
				children: [
					{
						path: '/app/dashboard/tasks',
						name: 'Dashboard - Tasks',
						meta: { title: 'Sub Tasks', requiresAuth: true },
						component: () => import('@/plugins/tm@dashboard/tasks/tasks.vue')
					},
					{
						path: '/app/dashboard/teams',
						name: 'Dashboard - Teams',
						meta: { title: 'Teams Overview', requiresAuth: true },
						component: () => import('@/plugins/tm@dashboard/teams/teams.vue')
					},
					{
						path: '/app/dashboard/projects',
						name: 'Dashboard - Projects',
						meta: { title: 'Projects overview', requiresAuth: true },
						component: () => import('@/plugins/tm@dashboard/projects/projects.vue')
					},

					{
						path: '/app/overview/unscheduled',
						name: 'Overview - Unscheduled tasks',
						meta: { title: 'Unscheduled Sub Tasks', requiresAuth: true },
						component: () => import('@/plugins/tm@overview/tasks-unscheduled/tasks-unscheduled.vue')
					},
					{
						path: '/app/overview/ongoing',
						name: 'Overview - Ongoing tasks',
						meta: { title: 'Ongoing Sub Tasks', requiresAuth: true },
						component: () => import('@/plugins/tm@overview/tasks-ongoing/tasks-ongoing.vue')
					},
					{
						path: '/app/overview/teams',
						name: 'Overview - Teams',
						meta: { title: 'Teams', requiresAuth: true },
						component: () => import('@/plugins/tm@overview/teams/teams.vue')
					},
					{
						path: '/app/dashboard/user',
						name: 'Dashboard - User',
						meta: { title: 'Profile', requiresAuth: true },
						component: () => import('@/plugins/tm@profile/profile/my-profile.vue')
					},
					{
						path: '/app/search',
						name: 'Search',
						meta: { title: 'Search Results', requiresAuth: true },
						component: () => import('@/plugins/lib@search/search/search.vue')
					}
				]
			}
		]
	},
	{
		path: '/:catchAll(.*)',
		name: '404',
		component: () => import('@/plugins/w@screen/404/404.vue'),
		meta: {requiresAuth: null}
	}
]

const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes
})

router.beforeEach((to, from, next) => {
	const isLoggedIn = store.getters['auth/isLoggedIn']

	if (to.meta.requiresAuth === true) {
		if (isLoggedIn)
			return next()
		else
			return next({name: 'Login'})
	} else if (to.meta.requiresAuth === false) {
		if (!isLoggedIn)
			return next()
		else
			return next({name: 'Dashboard - Tasks'})
	} else {
		return next()
	}
})

export default router
