<template>
    <v-row no-gutters class="align-center">
		<v-col cols="5">
			<v-sheet class="py-3">
				<div class="d-flex align-center">
					<v-icon icon="mdi-account-star-outline" size="large"></v-icon>
					<p class="mx-3">Vytvoril</p>
				</div>
			</v-sheet>
		</v-col>
        
		<v-col cols="7">
			<v-sheet class="py-3 d-flex align-center">
				<p class="employee-selected-avatar d-flex justify-center align-center">
					{{ employee ? employee._initials : '?' }}
				</p>
				<p class="ml-2">{{ employee ? employee._fullName : $t('Unasigned') }}</p>
			</v-sheet>
		</v-col>
	</v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	props: {
		employeeId: {
			type: [Number],
			required: false
		}
	},

	computed: {
		...mapGetters('tm', ['enrichedEmployees']),

		employee() {
			return this.enrichedEmployees.find(employee => employee.id == this.employeeId)
		}
	}
}
</script>