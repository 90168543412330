<template>
	<v-dialog v-model="visible" max-width="750" persistent>
		<v-card>
			<div class="d-flex justify-space-between align-center">
				<h1>
					Ste si istý, že chcete zmazať tím <b>{{ team.name }}</b>?
				</h1>
				<v-icon @click.stop="closeModal()" icon="mdi-close" size="large"></v-icon>
			</div>

			<v-card-actions class="mt-10 d-flex justify-start align-center mobile-view">
				<v-btn class="button primary half" variant="flat" @click="deleteTeam()" :disabled="isDeleting">ÁNO</v-btn>
				<v-btn class="button primary half outline" @click.stop="closeModal()">ZRUŠIŤ</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import modalMixin from '@/plugins/tm@modal/_mixins/modal.mixin'

export default {
	mixins: [ modalMixin ],
	emits: ['update'],

	data() {
		return {
			visible: false,
			team: null,
			isDeleting: false
		}
	},

	methods: {
		async showModal({team}) {
			this.team = team
			this.visible = true
		},

		closeModal() {
			this.visible = false
		},

		async deleteTeam() {
			if (this.isDeleting) return 

			this.isDeleting = true
			try {
				await this.$axios.delete_auth(`v1/teams/${this.team.id}`)
				this.visible = false
				this.$emit('update', this.team)
				this.$store.commit('tm/delete_team', this.team)
			} catch (error) {
				this.$toast.error(this.$parseError(error))
			} finally {
				this.isDeleting = false
			}
		}
	}
}
</script>