<template>
	<v-dialog v-model="visible" max-width="500" persistent>
		<v-card>
			<div class="d-flex justify-space-between align-center">
				<h4 class="mt-3">Vyberte časový úsek, ktorý chcete odstrániť</h4>

				<v-icon
					@click.stop="closeModal()"
					icon="mdi-close"
					size="large"
				></v-icon>
			</div>
			<div v-for="(timeblock, index) in subtask.timeblocks" :key="timeblock.id" class="d-flex align-center justify-center mb-3 mt-3">
				<p class="task-badge rounded-pill" :class="{'strike-through': timeblock.is_finished}">{{ tmUtils.formatDateTime(timeblock.start) }} - {{ tmUtils.formatDateTime(timeblock.end) }}</p>
				<v-icon @click.stop="subtask.id ? deleteTimeblock(timeblock) : deleteTimeblockWithoutId(index)" class="close-icon circle-border cursor-pointer ml-3 text-red" icon="mdi-close" size="medium" />
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
import modalMixin 	from '@/plugins/tm@modal/_mixins/modal.mixin'
import tmUtils 		from '@/plugins/tm@_utils/tm.utils'

export default {
	mixins: [ modalMixin ],
	emits: ['on-subtask-edited'],

	data() {
		return {
			visible: false,
			subtask: null,
			isDeleting: false,
			tmUtils
		}
	},

	methods: {
		async showModal({ subtask }) {
			this.subtask = subtask
			this.visible = true
		},

		closeModal() {
			this.visible = false
		},

		async deleteTimeblock({ id }) {
			const subtask = JSON.parse(JSON.stringify(this.subtask))
			subtask.timeblocks = subtask.timeblocks.filter(timeblock => timeblock.id != id)
			if(subtask.deadline) subtask.deadline = tmUtils.formatDateTimeForBackend(subtask.deadline)

			this._update(subtask)
		},

		deleteTimeblockWithoutId(i) {
			this.$emit('delete-timeblock')
		},

		async _update(data) {
			try {
				const timesheets = data.timesheets.map((timesheet) => {
					delete timesheet.employee_name
					return timesheet
				})

				const { subtask } = await this.$axios.put_auth(`v1/subtasks/${data.id}`, {...data, timesheets})

				this.closeModal()
				this.$events.emit('on-subtask-edited', subtask)
			} catch (error) {
				this.$toast.error(error.message || error)
			}
		},
	}
}
</script>

<style lang="sass" scoped>
.close-icon:hover
	background: red
	color: #fff !important
</style>