<template>
	<A-mastertask-name      	:name="subtask._master_task.name" :id="subtask.master_task_id" :color="subtask._master_task.color" @close-modal="$emit('close-modal')" />
    <A-mastertask-status    	:isFinished="subtask.is_finished" />
	<A-task-deadline 			:subtask="subtask" />
	<A-task-timeblocks			:timeblocks="subtask.timeblocks" :subtask="subtask" @update-subtask="$emit('update-subtask', $event)" />
    <Z-created-by 				:employee-id="subtask.created_by" />
    <A-task-assigned-employee  	:employee="subtask._employee" />
    <A-task-planned-time        :hours="subtask.duration" />
    <A-task-remaining-time      :hours="subtask.duration - subtask.spent_time" />
    <A-task-spent-time          :hours="subtask.spent_time" :subtask="subtask" />
    <A-task-description         :description="subtask.description" :mastertask-description="subtask.master_task_description" />
    <Z-files                    :files="subtask.files" />
</template>

<script>
import AMastertaskName          from './_components/a-mastertask-name.vue'
import AMastertaskStatus        from './_components/a-mastertask-status.vue'
import ATaskTimeblocks    		from './_components/a-task-timeblocks.vue'
import ZCreatedBy    			from '../../_components/read-only/z-created-by.vue'
import ATaskAssignedEmployee    from './_components/a-task-assigned-employee.vue'
import ATaskPlannedTime         from './_components/a-task-planned-time.vue'
import ATaskSpentTime           from './_components/a-task-spent-time.vue'
import ATaskRemainingTime       from './_components/a-task-remaining-time.vue'
import ATaskDeadline 			from './_components/a-task-deadline.vue'
import ATaskDescription         from './_components/a-task-description.vue'
import ZFiles 					from '@/plugins/tm@modal/_components/read-only/z-files.vue'

export default {
	emits: ['close-modal', 'update-subtask'],

    components: {
        ZFiles,
        AMastertaskName,
        AMastertaskStatus,
		ATaskTimeblocks,
		ZCreatedBy,
        ATaskAssignedEmployee,
        ATaskPlannedTime,
        ATaskSpentTime,
        ATaskRemainingTime,
        ATaskDescription,
		ATaskDeadline
    },
    
	props: {
		subtask: {
			type: Object,
			required: true
		}
	}
}
</script>
