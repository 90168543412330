<template>
    <div class="w-100 d-flex align-center">
		<input type="file" multiple name="file" id="fileInput" class="hidden-input" accept="*"
			@change="onChange" ref="fileUpload"
		/>

		<v-btn class="upload-btn" @click="$refs.fileUpload.click()">
			<v-icon class="mx-1" icon="mdi-cloud-upload-outline"></v-icon>NAHRAŤ
		</v-btn>

		<div v-if="uploadedFiles" class="m-2 d-flex align-center flex-wrap">
			<div
				v-for="file in uploadedFiles"
				:key="file.name"
				class="mx-2"
			>
				<v-chip class="mt-2">
					{{ file.name }}
					<v-icon class="mx-1" icon="mdi-paperclip"></v-icon>
					<p>{{ (file.size / (1024 * 1024)).toFixed(2) }} MB</p>
					<v-icon size="small" icon="mdi-close" @click.stop="remove(uploadedFiles.indexOf(file))"></v-icon>
				</v-chip>
			</div>
			<div v-if="isSaving" class="d-flex align-center">
				<img v-if="isSaving" class="gif-loader" :src="require('@/plugins/app/_img/spinner.gif')" alt="Loading...">
			</div>
		</div>
    </div>
</template>

<script>
export default {
	emit: ['files-uploaded'],

    props: {
		files: {
			type: Array,
			default: () => []
		},

		isSaving: {
			type: Boolean,
			required: false
		}
	},

	data() {
		return {
			uploadedFiles: []
		}
	},

	created() {
		this.uploadedFiles = this.files
	},

    methods: {
        onChange() {
			const files = [...this.$refs.fileUpload.files]
			const maxSize = 50 * 1024 * 1024
			const oversizedFiles = files.filter(file => file.size > maxSize)
			if (oversizedFiles.length > 0) {
			this.$toast.error('File is too large. Maximum file size is 50 MB.')
				return
			}

			this.uploadedFiles = [...this.uploadedFiles, ...files]
			this.$emit('files-uploaded', this.uploadedFiles)

			this.$refs.fileUpload.value = ''
        },

		remove(index) {
			this.uploadedFiles.splice(index, 1)
		}
    },
}
</script>

<style lang="sass" scoped>
.upload-btn
	color: #6200EE
	border-radius: 4px
	border: 1px solid #E5E5E5
.hidden-input
	opacity: 0
	overflow: hidden
	position: absolute
	width: 1px
	height: 1px

.gif-loader
	height: 30px
</style>