<template>
	<p class="mt-5">Prílohy</p>
	<div class="w-100 d-flex align-center">
		<W-loadable :content="files" emptyMessage="Žiadne prílohy neboli nahraté">
			<div v-for="(file, index) in files" :key="file.id" class="d-flex align-center flex-wrap" :class="[index === 0 ? 'ml-0' : 'ml-2']">
				<v-chip class="mt-2">
					<p @click="downloadFile(file)" class="cursor-pointer">{{ file.name }}</p>
					<v-icon class="mx-1" icon="mdi-paperclip"></v-icon>
					<p>{{ (file.file_size / (1024 * 1024)).toFixed(2) }} MB</p>
				</v-chip>
				<div v-if="file.isLoading" class="d-flex align-center">
					<img class="gif-loader" :src="require('@/plugins/app/_img/spinner.gif')" alt="Loading...">
					<p class="ml-2">Súbor sa sťahuje, počkajte prosím</p>
				</div>
			</div>
		</W-loadable>
	</div>
</template>

<script>
import * as FileSaver from "file-saver"

export default {
    props: {
        files: {
            type: Array,
            default: () => []
        }
    },

	data() {
		return {
			isLoading: false
		}
	},

	methods: {
		async downloadFile(file) {
            file.isLoading = true // Show loader

			try {
				const response = await fetch(file.path)
				const blob = await response.blob()
				FileSaver.saveAs(blob, file.name)
			} catch (error) {
				this.$toast.error(this.$parseError(error))
			} finally {
				file.isLoading = false
			}
		}
	}
}
</script>

<style lang="sass" scoped>
.gif-loader
	height: 30px
	margin-left: 30px
</style>