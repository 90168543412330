import axios from 'axios'
import store from '@/store'
import toastUtils from '@/plugins/w/toast/utils/toast.utils'

axios.defaults.baseURL = process.env.VUE_APP_WAXIOS_URL

export default {
	async get(url: string, config?: any) {
		return axios.get(url, config)
			.then(response => response.data)
			.catch(error => Promise.reject(toastUtils.parseAndTranslate(error)))
	},

	async get_auth(url: string, config?: any) {
		config = this._config(config)

		return axios.get(url, config)
			.then(response => response.data)
			.catch(error => Promise.reject(toastUtils.parseAndTranslate(error)))
	},

	async get_data(url: string, config?: any) {
		return axios.get(url, config)
			.then(response => response.data.data)
			.catch(error => Promise.reject(toastUtils.parseAndTranslate(error.response || error)))
	},

	async get_auth_data(url: string, config?: any) {
		config = this._config(config)

		return axios.get(url, config)
			.then(response => Promise.resolve(response.data.data))
			.catch(error => Promise.reject(toastUtils.parseAndTranslate(error.response || error)))
	},

	async post(url: string, params = null, config?: any) {

		return axios.post(url, this._objectToFormData(params), config)
			.then(response => response.data)
			.catch(error => Promise.reject(toastUtils.parseAndTranslate(error)))
	},

	async post_auth(url: string, params = null, config?: any) {
		config = this._config(config)

		return axios.post(url, this._objectToFormData(params), config)
			.then(response => response.data)
			.catch(error => Promise.reject(toastUtils.parseAndTranslate(error)))
	},

	async post_data(url: string, params = null, config?: any) {

		return axios.post(url, this._objectToFormData(params), config)
			.then(response => response.data.data)
			.catch(error => Promise.reject(toastUtils.parseAndTranslate(error.response || error)))
	},

	async post_auth_data(url: string, params = null, config?: any) {
		config = this._config(config)

		return axios.post(url, this._objectToFormData(params), config)
			.then(response => response.data.data)
			.catch(error => Promise.reject(toastUtils.parseAndTranslate(error.response || error)))
	},

	async delete(url: string, config?: any) {
		return axios.delete(url, config)
			.then(response => response.data)
			.catch(error => Promise.reject(toastUtils.parseAndTranslate(error)))
	},

	async delete_auth(url: string, config?: any) {
		config = this._config(config)
		return axios.delete(url, config)
			.then(response => response.data)
			.catch(error => Promise.reject(toastUtils.parseAndTranslate(error)))
	},

	async delete_data(url: string, config?: any) {
		return axios.delete(url, config)
			.then(response => response.data.data)
			.catch(error => Promise.reject(toastUtils.parseAndTranslate(error.response || error)))
	},

	async delete_auth_data(url: string, config?: any) {
		config = this._config(config)

		return axios.delete(url, config)
			.then(response => response.data.data)
			.catch(error => Promise.reject(toastUtils.parseAndTranslate(error.response || error)))
	},

	async patch(url: string, params = null, config?: any) {
		return axios.patch(url, this._objectToFormData(params), config)
			.then(response => response.data)
			.catch(error => Promise.reject(toastUtils.parseAndTranslate(error)))
	},

	async patch_auth(url: string, params = null, config?: any) {
		config = this._config(config)

		return axios.patch(url, this._objectToFormData(params), config)
			.then(response => response.data)
			.catch(error => Promise.reject(toastUtils.parseAndTranslate(error)))
	},

	async patch_auth_form(url: string, params = null, config?: any) {
		config = this._config(config)
		config.headers['Content-Type'] = 'multipart/form-data'
		const formData = this._objectToFormData(params)
		formData.append('_method', 'put')
		config.transformRequest = formData => formData

		return axios.post(url, formData, config)
			.then(response => response.data)
			.catch(error => Promise.reject(toastUtils.parseAndTranslate(error)))
	},

	async patch_data(url: string, params = null, config?: any) {
		return axios.patch(url, this._objectToFormData(params), config)
			.then(response => response.data.data)
			.catch(error => Promise.reject(toastUtils.parseAndTranslate(error.response || error)))
	},

	async patch_auth_data(url: string, params = null, config?: any) {
		config = this._config(config)

		return axios.patch(url, this._objectToFormData(params), config)
			.then(response => response.data.data)
			.catch(error => Promise.reject(toastUtils.parseAndTranslate(error.response || error)))
	},

	async put(url: string, params = null, config?: any) {
		return axios.put(url, this._objectToFormData(params), config)
			.then(response => response.data)
			.catch(error => Promise.reject(toastUtils.parseAndTranslate(error)))
	},

	async put_auth(url: string, params = null, config?: any) {
		config = this._config(config)

		return axios.put(url, {data: params}, config)
			.then(response => response.data)
			.catch(error => Promise.reject(toastUtils.parseAndTranslate(error)))
	},

	async put_data(url: string, params = null, config?: any) {
		return axios.put(url, this._objectToFormData(params), config)
			.then(response => response.data.data)
			.catch(error => Promise.reject(toastUtils.parseAndTranslate(error.response || error)))
	},

	async put_auth_data(url: string, params = null, config?: any) {
		config = this._config(config)

		return axios.put(url, {data: params}, config)
			.then(response => response.data.data)
			.catch(error => Promise.reject(toastUtils.parseAndTranslate(error.response || error)))
	},

	_config(config: any) {
		if (config) console.error('NOT IMPLEMENTED')

		if (!store.getters['auth/token']) {
			return {headers: {}}
		}

		return {
			headers: {
				Authorization: `Bearer ${store.getters['auth/token']}`
			}
		}
	},

	_buildFormData(formData, data, parentKey) {
		if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File))
			Object.keys(data).forEach(key => this._buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key));
		else
			formData.append(parentKey, data == null ? '' : data);
	},

	_objectToFormData(data) {
		const formData = new FormData()
		this._buildFormData(formData, {data: data})

		return formData
	}
}