<template>
    <v-row no-gutters class="align-center">
		<v-col cols="5">
			<v-sheet class="py-3">
				<div class="d-flex align-center">
					<v-icon icon="mdi-account-outline" size="large"></v-icon>
					<p class="mx-3">Priradený</p>
				</div>
			</v-sheet>
		</v-col>
        
		<v-col cols="7">
			<v-sheet class="py-3 d-flex align-center">
				<p class="employee-selected-avatar d-flex justify-center align-center">
					{{ employee.name.charAt(0) + employee.surname.charAt(0) }}
				</p>
				<p class="ml-2" v-html="`${employee.name} ${employee.surname}`" />
			</v-sheet>
		</v-col>
	</v-row>
</template>

<script>
export default {
	props: {
		employee: {
			type: Object,
			required: true
		}
	},

	// methods: {
	// 	async deleteEmployee() {
	// 		try {
	// 			const tempSubtask 	= {...this.subtask, employee_id: null, deadline: moment(this.subtask.deadline).format('YYYY-MM-DD HH:mm:ss') }
	// 			const { subtask } 	= await this.$axios.put_auth(`v1/subtasks/${tempSubtask.id}`, tempSubtask)
	// 			// employee_id
	// 			this.$emit('on-subtask-edited', subtask)
	// 		} catch (error) {
	// 			this.$toast.error(error)
	// 		}
	// 	},
    // }
}
</script>