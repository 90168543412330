<template>
	<v-dialog v-model="visible" max-width="750" persistent>
		<v-card>
			<div class="d-flex justify-space-between align-center">
				<h1>Ste si istý, že chcete {{ subtask.is_finished ? 'znovu otvoriť' : 'dokončiť' }} sub task, z mastertasku <b>{{ subtask?.master_task?.name || subtask.master_task }}</b>?</h1>
				<v-icon
					@click.stop="closeModal()"
					icon="mdi-close"
					size="large"
				></v-icon>
			</div>
			<v-card-actions class="mt-10 d-flex justify-start align-center mobile-view">
				<v-btn class="button primary half" variant="flat" @click="complete()">ÁNO</v-btn>
				<v-btn class="button primary half outline" @click.stop="visible = false">ZRUŠIŤ</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import modalMixin from '@/plugins/tm@modal/_mixins/modal.mixin'

export default {
	mixins: [ modalMixin ],
	emits: ['close', 'on-subtask-edited'],

	data() {
		return {
			visible: false,
			subtask: null
		}
	},

	methods: {
		async showModal({ subtask }) {
			this.subtask = subtask
			this.visible = true
		},

		async complete() {
			try {
				const tempSubtask 	= {...this.subtask, is_finished: this.subtask.is_finished ? 0 : 1, timesheets: this.subtask.timesheets.map((timesheet) => {
					delete timesheet.employee_name
					return timesheet
				})}
				const { subtask } = await this.$axios.put_auth(`v1/subtasks/${tempSubtask.id}`, tempSubtask)

				this.visible = false
				this.$emit('on-subtask-edited', subtask)
			} catch (error) {
				this.$toast.error(this.$parseError(error))
			}
		},

		closeModal() {
			this.visible = false
		}
	}
}
</script>