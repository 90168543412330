<template>
	<v-row no-gutters>
		<v-col cols="5">
			<v-sheet class="py-3">
				<div class="d-flex align-center">
					<v-icon icon="mdi-clock-time-three-outline" size="large"></v-icon>
					<p class="mx-3">Odpracovaný čas</p>
				</div>
			</v-sheet>
		</v-col>

		<v-col cols="7">
			<v-sheet class="py-3">
				<p :class="isExceeded ? 'text-red-darken-4' : 'text-black'">{{ hours }} h</p>
			</v-sheet>
		</v-col>
	</v-row>
</template>

<script>
export default {
	props: {
		hours: {
			type: Number,
			required: true
		},

		isExceeded: {
			type: Boolean,
			required: true
		}
	}
}
</script>