import axios from '@/plugins/w@custom/axios/models/axios'
import store from '@/store'

export default {
	namespaced: true,

	state: {
		mastertasks: null,
		employees: null,
		teams: null,
		projects: null,
		unscheduledSubtasks: null,
		ongoingSubtasks: null,
		initialized: false
	},

	mutations: {
		set_mastertasks(state: any, mastertasks: any) {
			state.mastertasks = mastertasks
		},

		set_employees(state: any, employees: any) {
			state.employees = employees

			// TODO: Remove
			// const employee = employees.find(employee => employee.email == 'zachariah.vonrueden@jandl.sk')
			// store.commit('auth/auth_success', {
			// 	user: employee
			// })
		},

		set_teams(state: any, teams: any) {
			state.teams = teams
		},

		set_projects(state: any, projects: any) {
			state.projects = projects
		},

		set_unscheduled_subtasks(state: any, subtasks: any) {
			state.unscheduledSubtasks = subtasks
		},

		set_ongoing_subtasks(state: any, subtasks: any) {
			state.ongoingSubtasks = subtasks
		},

		update_team(state: any, team: any) {
			state.teams = state.teams.map(t => {
				if (t.id == team.id)
					return team

				return t
			})
		},

		save_team(state: any, team: any) {
			state.teams.push(team)
		},

		delete_team(state: any, team: any) {
			state.teams = state.teams.filter(t => t.id != team.id)
		},

		update_subtask(state: any, subtask: any) {
			state.unscheduledSubtasks 	= state.unscheduledSubtasks.map(st 		=> st.id == subtask.id ? subtask : st)
			state.ongoingSubtasks 		= state.ongoingSubtasks.map(st 			=> st.id == subtask.id ? subtask : st)
			
			// state.mastertasks 			= state.mastertasks.map(mastertask 		=> {
			// 	mastertask.subtasks 	= mastertask.subtasks.map(st => st.id == subtask.id ? subtask : st)
			// 	return mastertask
			// })
		},

		delete_subtask(state: any, subtask: any) {
			state.unscheduledSubtasks 	= state.unscheduledSubtasks.filter(st 	=> st.id != subtask.id)
			state.ongoingSubtasks 		= state.ongoingSubtasks.filter(st 		=> st.id != subtask.id)

			// state.mastertasks 			= state.mastertasks.map(mastertask 		=> {
			// 	mastertask.subtasks = mastertask.subtasks.filter(st => st.id != subtask.id)
			// 	return mastertask
			// })
		},

		initialized(state: any) {
			state.initialized = true
		}
	},

	actions: {
		initialize({dispatch, commit}: {dispatch: any, commit: any}) {
			return Promise.all([
				dispatch('loadMastertasks'),
				dispatch('loadEmployees'),
				dispatch('loadTeams'),
				dispatch('loadProjects'),
				dispatch('loadUnscheduledSubtasks'),
				dispatch('loadOngoingSubtasks')
			]).then(() => commit('initialized'))
		},

		reloadSubtasks({dispatch}: {dispatch: any}) {
			return Promise.all([
				dispatch('loadMastertasks'),
				dispatch('loadProjects'),
				dispatch('loadUnscheduledSubtasks'),
				dispatch('loadOngoingSubtasks')
			])
		},

		reloadMastertasks({dispatch}: {dispatch: any}) {
			return Promise.all([
				dispatch('loadMastertasks')
			])
		},

		loadMastertasks({commit}: {commit: any}) {
			return new Promise((resolve, reject) => {
				// axios.get_auth('v1/mastertasks')
				axios.get_auth('v1/mastertasks/?simple=true')
					.then(({ mastertasks }) => {
						commit('set_mastertasks', mastertasks)
						resolve(mastertasks)
					})
					.catch(error => {
						commit('set_mastertasks', {error: error})
						reject(error)
					})
			})
		},

		loadEmployees({commit}: {commit: any}) {
			return new Promise((resolve, reject) => {
				axios.get_auth_data('v1/employees')
					.then(employees => {
						commit('set_employees', employees)
						resolve(employees)
					})
					.catch(error => {
						commit('set_employees', {error: error})
						reject(error)
					})
			})
			
		},

		loadTeams({commit}: {commit: any}) {
			return new Promise((resolve, reject) => {
				axios.get_auth_data('v1/teams')
					.then(teams => {
						commit('set_teams', teams)
						resolve(teams)
					})
					.catch(error => {
						commit('set_teams', {error: error})
						reject(error)
					})
			})
		},

		loadProjects({commit}: {commit: any}) {
			return new Promise((resolve, reject) => {
				axios.get_auth_data('v1/projects')
					.then(project => {
						commit('set_projects', project)
						resolve(project)
					})
					.catch(error => {
						commit('set_projects', {error: error})
						reject(error)
					})
			})
			
		},

		loadUnscheduledSubtasks({commit}: {commit: any}, employeeId = store.getters['auth/user']?.id) {
			return new Promise((resolve, reject) => {
				axios.get_auth(`v1/subtasks?type=unscheduled&employee_id=${employeeId}`)
					.then(({subtasks}) => {
						commit('set_unscheduled_subtasks', subtasks)
						resolve(subtasks)
					})
					.catch(error => {
						commit('set_unscheduled_subtasks', {error: error})
						reject(error)
					})
			})
		},

		loadOngoingSubtasks({commit}: {commit: any}, employeeId = store.getters['auth/user']?.id) {
			return new Promise((resolve, reject) => {
				axios.get_auth(`v1/subtasks?type=ongoing&employee_id=${employeeId}`)
					.then(({subtasks}) => {
						commit('set_ongoing_subtasks', subtasks)
						resolve(subtasks)
					})
					.catch(error => {
						commit('set_ongoing_subtasks', {error: error})
						reject(error)
					})
				})
		}

	},

	getters: {
		mastertasks: (state: any) => {
			return state.mastertasks
		},

		employees: (state: any) => {
			return state.employees
		},

		enrichedEmployees: (state: any) => {
			if (!state.employees) return []
			
			return state.employees.map(employee => ({
				...employee, 
				_fullName: `${ employee.name } ${ employee.surname }`,
				_initials: employee.name.charAt(0) + employee.surname.charAt(0)
			})).sort((a, b) => a.name < b.name ? -1 : 1)
		},

		teams: (state: any) => {
			return state.teams
		},

		projects: (state: any) => {
			return state.projects
		},

		unscheduledSubtasks: (state: any) => {
			return state.unscheduledSubtasks
		},

		ongoingSubtasks: (state: any) => {
			return state.ongoingSubtasks
		},

		isInitialized: (state: any) => {
			return state.initialized
		}
	}
}