/* eslint-disable */
import axios from 'axios'
import store from '@/store'

async function _unauthorised(error: any) {
	if (error?.response?.data?.message == 'Unauthenticated.')
		return store.dispatch('auth/logout')
	
	return Promise.reject(error)
}

axios.interceptors.response.use(
	(response) => response,
	(error) => _unauthorised(error),
)