<template>
	<Z-status 				:status="mastertask.is_finished" />
	<Z-deadline 			:deadline="mastertask.deadline" />
	<Z-created-by 			:employee-id="mastertask.created_by" />
	<Z-team 				:team="mastertask.team" :employees="mastertask._allEmployees || mastertask.employees" />
	<Z-planned-time        	:hours="mastertask.duration" />
    <Z-remaining-time      	:hours="mastertask.duration - mastertask.time_worked" />
	<Z-spent-time     		:hours="mastertask.time_worked"  :is-exceeded="mastertask.time_worked > mastertask.duration" />
	<Z-description			:description="mastertask.description" />
	<Z-files				:files="mastertask.files" />
</template>

<script>
import ZCreatedBy 		from '../../_components/read-only/z-created-by.vue'
import ZDeadline 		from '../../_components/read-only/z-deadline.vue'
import ZDescription 	from '../../_components/read-only/z-description.vue'
import ZFiles 			from '../../_components/read-only/z-files.vue'
import ZPlannedTime 	from '../../_components/read-only/z-planned-time.vue'
import ZRemainingTime 	from '../../_components/read-only/z-remaining-time.vue'
import ZSpentTime 		from '../../_components/read-only/z-spent-time.vue'
import ZStatus 			from '../../_components/read-only/z-status.vue'
import ZTeam 			from '../../_components/read-only/z-team.vue'

export default {
	components: {
		ZCreatedBy,
		ZDeadline,
		ZDescription,
		ZFiles,
		ZPlannedTime,
		ZRemainingTime,
		ZSpentTime,
		ZStatus,
		ZTeam
	},

	props: {
		mastertask: {
			type: Object,
			required: true
		}
	}
}
</script>