<template>
    <div class="timeblock w-100">
        <div v-if="deletable && editable" class="x-button">
            <button @click="$emit('removeTimeblock')" class="x-button">x</button>
        </div>
        <div @click="openDatepicker('datepicker')" class="datepicker-wrapper" ref="datepicker-wrapper">
            <Datepicker :model-value="timeblock.date" ref="datepicker" @focus="onDateFocus" @update:model-value="updateDate" format="dd.MM.yyyy" locale="sk" select-text="Vybrať" cancel-text="Zrušiť" placeholder="Dátum" :auto-apply="true" :enable-time-picker="false" class="mb-5 modal-input datepicker-input" />
        </div>
        <div class="splitted-time-inputs">
            <v-text-field :value="timeblock.starts_at_time" @input="updateStartTime" @focus="onInputFocus" :class="{ error: v$?.timeblock.starts_at_time.$errors.length }" type="time" variant="solo" bg-color="white" label="Začiatok" />
            <v-text-field :value="timeblock.ends_at_time" @input="updateEndTime" @focus="onInputFocus" :class="{ error: v$?.timeblock.ends_at_time.$errors.length }" type="time" variant="solo" bg-color="white" label="Koniec" />
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { requiredIf } from '@vuelidate/validators'
import Datepicker from '@vuepic/vue-datepicker'
import VuetifyFixFocusMixin from '@/plugins/tm@modal/_mixins/vuetify-fix-focus'

export default {
    mixins: [VuetifyFixFocusMixin],

    components: {
        Datepicker
    },

    props: {
        timeblock: {
            type: Object,
            required: true
        },

        deletable: {
            type: Boolean,
            default: false
        },

        editable: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            v$: useVuelidate(),
        }
    },

    validations() {
        return {
            timeblock: {
                starts_at_time: { required: requiredIf(this.timeblock.date) },
                ends_at_time: { required: requiredIf(this.timeblock.date) }
            }
        }
    },

    methods: {
        async openDatepicker(ref) {
			this.$refs[ref].openMenu()
            this.$nextTick(() => {
                this.$refs['datepicker-wrapper'].scrollIntoView({ behavior: 'instant', block: 'center', inline: 'center' })
                this.$nextTick(() => {
                    const popup = document.querySelector('.dp__menu.dp__menu_index')
                    const isBottom = popup.querySelector('.dp__arrow_bottom')
                    const yDifference = isBottom ? 0 - popup.clientHeight - 42 : 35
                    popup.style.top = window.innerHeight / 2 + yDifference + 'px'
                })
            })
		},
        updateDate(date) {
            const timeblock = { ...this.timeblock }
            if (date) timeblock.date = date
            this.$emit('updateTimeblock', timeblock)
        },
        updateStartTime(event) {
            const timeblock = { ...this.timeblock }
            timeblock.starts_at_time = event.target.value
            this.$emit('updateTimeblock', timeblock)
        },
        updateEndTime(event) {
            const timeblock = { ...this.timeblock }
            timeblock.ends_at_time = event.target.value
            this.$emit('updateTimeblock', timeblock)
        },
    }
}
</script>

<style lang="sass" scoped>
.timeblock
    position: relative
    border-top: 1px solid #e0e0e0
    padding: 20px 0 5px 0
    .splitted-time-inputs
        display: flex !important
        flex-direction: row !important
        gap: 15px
        margin-bottom: -11px

    .x-button
        display: flex
        justify-content: flex-end
        align-items: center
        position: relative
        bottom: 5px

        button
            margin-right: 5px

.error input
    border: 1px solid red !important

.datepicker-wrapper
    width: 100%
    .datepicker-input
        width: 100% !important
        pointer-events: none
</style>e